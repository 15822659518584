/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                var ind = $('#industry-selector :selected').text();
                var app = $('#application-selector :selected').text();
                if(ind !== 'Select Project Industry') {
                    $('.active-industry-filter').addClass('active');
                    $('.active-industry-filter').text(ind);
                }
                if(app !== 'Select Project Application'){
                    $('.active-application-filter').addClass('active');
                    $('.active-application-filter').text(app);
                }

                $('.request-demo-nav, .request-demo a').on('click', function(){
                    $('html, body').animate({
                        scrollTop: $('#contact-form--1').offset().top - 125
                    }, 500);
                });

                $('.testimonial-col').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.menu-height').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });
                $('.contact-height').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });
                $('.article-height').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.archive-article-content').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.archive-article-content h2').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.contact-people-single').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.product-col').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.product-custom-section').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.product-category-output .category-block').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.related-product-output .related-product').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.timeline-anchors').scrollToFixed({  });
                $('.switcher svg').click(function(){
                    $(this).parent()
                    .parent()
                    .find('.variation-image')
                    .toggleClass('show');

                    $(this).parent()
                    .parent()
                    .find('.embed-container')
                    .toggleClass('show');
                });
                $('.flexslider-about').flexslider({
                    animation: "slide",
                    controlNav: false,
                    customDirectionNav: $(".banner-custom-navigation a")
                });

                $('.b2-scroll').on('click', function(event) {

                    // Make sure this.hash has a value before overriding default behavior
                    if (this.hash !== "") {
                        // Prevent default anchor click behavior
                        event.preventDefault();

                        // Store hash
                        var hash = this.hash;

                        // Using jQuery's animate() method to add smooth page scroll
                        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                        $('html, body').animate({
                            scrollTop: $(hash).offset().top
                            }, 800, function(){

                            // Add hash (#) to URL when done scrolling (default click behavior)
                            if(hash!=='#Top'){
                                window.location.hash = hash;
                            }
                        });
                    } // End if
                });

                // Scroll and resize functionality to add a reduced class for styling  of items on scroll
                $(window).scroll(function () {
                    var scrollPercentage = 100 * ($(this).scrollTop() / $('body').height());
                    var scrollNumber = ($(this).scrollTop());

                    if (scrollPercentage >= 7 && scrollPercentage <= 100){
                        $('body').addClass('reduced');

                    } else {
                        $('body').removeClass('reduced');
                    }
                    if ($('.sp-elements__main-nav').length>0) {
                        if($(this).scrollTop()>300){
                            $('.sp-elements__main-nav.shop-active').addClass('sp-elements__main-nav--fixed');
                            $('.submenu-container').addClass('on-back-nav');
                        }else{
                            $('.sp-elements__main-nav.shop-active').removeClass('sp-elements__main-nav--fixed');
                            $('.submenu-container').removeClass('on-back-nav');
                        }
                    }

                });

                /* PDF Download Event Counter */
                $('a.pdf-dl').click(function() {
                    var href = $(this).attr('href');
                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'PDF Download: ' + href,
                        eventAction: 'download',
                        eventLabel: href
                    });
                });

                $('.search-toggle').on('click', function(e){
                    e.preventDefault();
                    $('.search-bar-top').addClass('active');
                });
                $('.close-custom').on('click', function(e) {
                    e.preventDefault();
                    $('.search-bar-top').removeClass('active');
                });

                $('.nav-toggle').on('click', function(){
                    $('.mobile-menu-output').slideToggle();
                });

                $('.mobile-menu .menu-item-has-children').on('click', function(e){
                    if($(e.target)[0].classList.contains('menu-item-has-children') ||
                        $(e.target)[0].parent().classList.contains('menu-item-has-children')
                    ){
                        $(this).toggleClass('menu-open');
                        $(this).find('.sub-menu').slideToggle();
                    }
                });

                $("ul[parent-id]").each(function(){
                    var parentId=$(this).attr("parent-id");
                    $(".menu-item[data-id='"+parentId+"']").addClass("menu-item-has-children")
                })
                $('.menu-navbar-sp-elements .menu-item-has-children').on('click', function(e){
                    e.preventDefault();
                    $('.menu-navbar-sp-elements .menu-item-has-children').not(this).removeClass('nav--open');
                    $(this).toggleClass('nav--open');
                    var dataId=$(this).attr("data-id");
                    var offsetTop=$("#Top").height();
                    $(".submenu-container").css("top",offsetTop)
                    $(".submenu-container > .first-submenu > ul").addClass('hide-submenu');
                    if($(this).hasClass('nav--open')){
                        $(".submenu-container").removeClass('closed');
                    }else{
                        $(".submenu-container").addClass('closed');
                    }
                    $("ul[parent-id='"+dataId+"']").removeClass("hide-submenu");

                });

                $('.mobile-menu-output .menu-item-has-children').on('click', function(e){
                    e.preventDefault();
                    const dataId=$(this).attr("data-id");
                    const offsetTop=$("#Top").height();
                    const oldMenuOffset = $('.mobile-menu-output').height();
                    $(".submenu-container").css("top",offsetTop - oldMenuOffset);
                    $(".submenu-container").removeClass('closed');
                    $(".submenu-container").height(window.innerHeight - (offsetTop - oldMenuOffset));
                    $("ul[parent-id='"+dataId+"']").removeClass("hide-submenu");
                });
                $('.back-to-main-menu').on('click', function(){
                    $(this).parent().addClass("hide-submenu");
                    $(".submenu-container").addClass('closed');

                });

                if($(window).width() < 991){
                    $('.main-sub-menu > .menu-item > .menu-category a').click(function(e){
                        e.preventDefault();
                        const offsetTop=$("#Top").height();
                        const oldMenuOffset = $('.mobile-menu-output').height();
                        $(this).parent()
                        .siblings('.second-submenu')
                        .removeClass('hide-submenu')
                        .height(window.innerHeight - (offsetTop - oldMenuOffset));
                    });
                    $('.second-submenu > ul > .menu-item > .menu-category a').click(function(e){
                        e.preventDefault();
                        const offsetTop=$("#Top").height();
                        const oldMenuOffset = $('.mobile-menu-output').height();
                        $(this).parent()
                        .siblings('.third-submenu')
                        .removeClass('hide-submenu')
                        .height(window.innerHeight - (offsetTop - oldMenuOffset));
                    });
                    $('.back-to-sub-menu').on('click', function(){
                        $(this).parent()
                        .parent()
                        .addClass("hide-submenu");
                    });
                }

                $('.volume-controls').on('click', function(){
                    $('.video-bg').prop("muted", !$('.video-bg').prop("muted"));
                    if ($('.volume-state').hasClass('fa-volume-off')) {
                        $('.volume-state').removeClass('fa-volume-off');
                        $('.volume-state').addClass('fa-volume-up');
                    } else{
                        $('.volume-state').removeClass('fa-volume-up');
                        $('.volume-state').addClass('fa-volume-off');
                    }
                });

                $(".custom-select").change(function() {
                    $(".project-selector-form").submit();
                });

                $('.custom-sp-header .mobile-toggle').on('click', function(){
                    const toggle = $('.mobile-icon-toggle');
                    $('body').toggleClass('sp-menu-active');

                    if(toggle.hasClass('fa-bars')) {
                        toggle.removeClass('fa-bars');
                        toggle.addClass('fa-times');
                    } else {
                        toggle.removeClass('fa-times');
                        toggle.addClass('fa-bars');
                    }
                });

                AOS.init();


            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page

                // Banner, slider and carousel
                $(window).on("load", function () {
                    $('#carousel').flexslider({
                        animation: "slide",
                        controlNav: false,
                        animationLoop: true,
                        slideshow: false,
                        itemWidth: 80,
                        itemMargin: 5,
                        move: 3,
                        asNavFor: '#slider',

                    });
                    $('#slider').flexslider({
                        controlNav: false,
                        animation: "fade",
                        animationLoop: true,
                        slideshow: true,
                        slideshowSpeed: 6000,
                        animationSpeed: 600,
                        //pauseOnHover: true,
                        sync: '#carousel',
                        start: function(){
                            $('#slider .first-show').removeClass('first-show');
                            $('#slider').removeAttr('style');
                        }
                    });
                });

                // Match height for panels
/*
                $('.top-height').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.bottom-height').matchHeight({
                    property: 'height',
                    target: null,
                    remove: false
                });
*/

                $("#eventModal").modal('show');
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
